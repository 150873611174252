import ApplicationLogo from "@/Components/ApplicationLogo";
import NavLink from "@/Components/NavLink";
import { Button } from "@/Components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { useProductCollections } from "@/Hooks/CollectionsHook";
import { navigate } from "@/Hooks/Functions";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import { useScroll } from "ahooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import UserPopover from "./UserPopover";
import ShoppingCart from "@/Modules/ShoppingCart";
import { debounce } from "lodash";

const CollectionPopover = ({ user }) => {

    const { collections } = useProductCollections();

    return <Popover>
        <PopoverTrigger asChild>
            <Button variant="link" className={cn("text-white px-0 gap-[1px] hover:text-teal-600")} >
                Collections <Icon icon="mdi:chevron-down" />
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-screen flex flex-col min-h-[200px] justify-center items-center rounded-none">
            <div className="max-w-7xl w-full flex md:flex-row flex-col justify-between item-stretch md:py-2 py-4">
                <div className="md:w-2/3 w-full grid lg:grid-cols-4 justify-center md:py-0 py-4 items-center md:grid-cols-3 gap-1 grid-cols-2">
                    {collections?.map((collection, i) => (
                        <Link key={collection.id} href={route('home.predesign-products-collections', { collection: collection.slug })} className="inline-flex group col-span-1">
                            <span className="text-primary text-sm font-semibold group-hover:text-teal-600 text-left">{collection.name}</span>
                        </Link>
                    ))}
                </div>
                <div className="min-h-[100px] md:w-1/3 w-full bg-gray-300 flex flex-col justify-center items-center bg-center bg-no-repeat bg-cover rounded-lg bg-blend-multiply  bg-predesigns-1">
                    <Link href={!user ? route('register') : route('home.predesign-products-collections', { category: "all" })}>
                        <Button variant="theme" type="button" className="border-2 border-white bg-teal-600/30  ">
                            {!user ? "Get started" : "View Collections"}
                            <Icon icon="mdi:arrow-right" />
                        </Button>
                    </Link>
                </div>
            </div>
        </PopoverContent>
    </Popover>
}
const HotItemsPopover = ({ user }) => {
    const links = useMemo(() => [
        {
            label: "Top Collections",
            icon: 'hugeicons:collections-bookmark',
            route: route('home.predesign-products-collections', { category: "all" })
        },
        {
            label: "Trending Products",
            icon: 'fluent:arrow-trending-lines-24-filled',
            route: route('home.predesign-products', { category: "popular" })
        },
        {
            label: "Best Sellers",
            icon: 'arcticons:bestprice',
            route: route('home.predesign-products', { category: "best-sellers" })
        },
        {
            label: "Recommended",
            icon: 'hugeicons:user-love-02',
            route: route('home.predesign-products', { category: "recommended" })
        },
    ], []);
    return <Popover>
        <PopoverTrigger asChild>
            <Button variant="link" className={cn("text-white px-0 gap-[1px] text-nowrap hover:text-teal-600")} >
                Hot Items <Icon icon="mdi:chevron-down" />
            </Button>
        </PopoverTrigger>
        <PopoverContent className="w-screen flex flex-col min-h-[200px] justify-center items-center rounded-none">
            <div className="max-w-7xl w-full flex md:flex-row flex-col justify-between item-stretch md:py-2 py-4">
                <div className="md:w-2/3 w-full grid lg:grid-cols-4 justify-center md:py-0 py-4 items-center md:grid-cols-3 md:gap-1 gap-2 grid-cols-2">
                    {links.map((collection, i) => (
                        <Link key={collection.icon} href={collection.route} className="flex gap-1 flex-col justify-center items-center group col-span-1">
                            <Icon icon={collection.icon} className="text-[3em] group-hover:text-teal-600  " />
                            <span className="group-hover:text-teal-600 font-semibold ">{collection.label}</span>
                        </Link>
                    ))}
                </div>
                <div className="min-h-[100px] md:w-1/3 w-full bg-gray-300 flex flex-col justify-center items-center bg-center bg-no-repeat bg-cover rounded-lg bg-blend-multiply  bg-predesigns-2">
                    <Link href={!user ? route('register') : route('home.predesign-products', { category: "all" })}>
                        <Button variant="theme" type="button" className="border-2 border-white bg-teal-600/30  ">
                            {!user ? "Get started" : "View Shop"}
                            <Icon icon="mdi:arrow-right" />
                        </Button>
                    </Link>
                </div>
            </div>
        </PopoverContent>
    </Popover>
}
export default function Heaader({ className }) {
    const scroll = useScroll(document);
    const [scrolled, setScrolled] = useState(false);
    const [open, setOpen] = useState(false);
    const { auth } = usePage().props;

    const debouncedSetScrolled = useCallback(
        debounce((value) => setScrolled(value), 100),
        []
    );

    useEffect(() => {
        if (scroll) {
            debouncedSetScrolled(scroll.top >= 100);
        }
    }, [scroll, debouncedSetScrolled]);

    const links = useMemo(() => [
        {
            label: "Home",
            routes: route('home'),
            component: <NavLink key="Home" href={route('home')} className={cn("text-white hover:text-teal-600")} >Home</NavLink>
        },
        {
            label: "Hot Items",
            routes: route('home.predesign-products', { category: "all" }),
            component: <HotItemsPopover user={auth?.user} key="Hot" />,
        },
        {
            label: "Templates",
            routes: route('home.predesign-templates', { collection: "all" }),
            component: <NavLink key="Templates" href={route('home.predesign-templates', { collection: "all" })} className={cn("text-white hover:text-teal-600")} >Templates</NavLink>
        },
        {
            label: "Collections",
            routes: route('home.predesign-products-collections', { category: "all" }),
            component: <CollectionPopover user={auth?.user} key="Collections" />,
        },
        {
            label: "Shop",
            routes: route('home.predesign-products', { category: 'all' }),
            component: <NavLink key="Shop" href={route('home.predesign-products', { category: 'all' })} className={cn("text-white hover:text-teal-600")} >Shop</NavLink>
        },
    ], [auth?.user]);

    return (
        <div className={cn(`w-screen relative bg-primary`, !scrolled ? "!bg-transparent" : "!bg-primary", className)} >

            <div className="max-w-7xl p-2 w-full mx-auto flex flex-row justify-between items-center gap-2">
                <div className="flex w-fit flex-row justify-start items-center gap-2">
                    <Button onClick={() => setOpen(true)} variant="icon" className="group md:hidden inline-block">
                        <Icon className="text-white group-hover:text-teal-600 text-3xl" icon="charm:menu-hamburger" />
                    </Button>
                    <Link className="md:inline-flex hidden" href={route('home')}>
                        <ApplicationLogo
                            white={true}
                            square={false}
                            className="w-fit md:h-[60px] h-[50px] fill-current text-gray-500"
                        />
                    </Link>
                    <Link className="md:hidden inline-flex" href={route('home')}>
                        <ApplicationLogo
                            white={true}
                            square={true}
                            className="w-fit md:h-[60px] !h-[40px] fill-current text-gray-500"
                        />
                    </Link>
                </div>
                <div className="md:flex hidden flex-row justify-end items-center gap-x-4">
                    {links.map((link, index) => link.component)}
                </div>
                <div className="flex flex-row justify-end items-center gap-x-4">
                    {auth?.user ? (
                        <>
                            <ShoppingCart />
                            <UserPopover iconColor="white" />
                        </>
                    ) : (
                        <>
                            <Link href={route("login")}>
                                <Button>Sign In</Button>
                            </Link>
                            <Link href={route("register")}>
                                <Button>Get Started</Button>
                            </Link>
                        </>
                    )}
                </div>
            </div>

            <div className={cn("absolute min-h-screen bg-primary top-0 left-0 w-screen z-[200]", open ? "" : "!hidden")}>
                <div className="w-full flex flex-col relative justify-center items-center gap-4 p-4 h-screen">
                    <Button variant="icon" onClick={() => setOpen(false)} className="absolute top-4 right-4">
                        <Icon className="text-white text-3xl" icon="charm:cross" />
                    </Button>
                    {links.map((link, index) => (
                        <Button variant="ghost"
                            key={index}
                            onClick={() => {
                                setOpen(false);
                                navigate(link.routes)
                            }}
                            className={cn(
                                "text-white",
                                scrolled
                                    ? "hover:text-teal-600"
                                    : "hover:text-primary",
                            )}
                        >
                            {link.label}
                        </Button>
                    ))}
                </div>
            </div>
        </div >
    );
}
