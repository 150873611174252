import { Toaster } from "@/Components/ui/sonner";
import { cn } from "@/lib/utils";
import ComingSoonModal from "@/Modules/ComingSoonModal";
import GlobalLoader from "@/Modules/GlobalLoader";
import { usePage } from "@inertiajs/react";
import AOS from "aos";
import { useEffect } from "react";
import { toast } from "sonner";
import Foooter from "./partials/Foooter";
import Heaader from "./partials/Heaader";

export default function HomeLayout({ headerClass, wrapperClass, children, hasFooter = true }) {
    const { flash } = usePage().props;

    useEffect(() => {
        if (flash?.message) {
            toast.success(flash.message);
        } else if (flash?.error) {
            toast.error(flash.error);
        }
        return () => toast.dismiss();
    }, [flash]);

    useEffect(() => {
        AOS.init({ duration: 1200 });
        AOS.refresh();
    }, []);

    return (
        <div className={cn("min-h-screen h-full overflow-y-auto w-screen flex flex-col bg-gray-100", wrapperClass)}>
            <div className={cn("w-screen fixed !z-[100] left-0 top-0 bg-theme", headerClass)}>
                <Heaader />
            </div>

            <Toaster
                position="top-right"
                expand={false}
                closeButton
                richColors
            />

            {children}

            {hasFooter && <Foooter />}
            <GlobalLoader />
            <ComingSoonModal />
        </div>
    );
}
