import Checkbox from "@/Components/Checkbox";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from "@/Components/ui/sheet";
import { encryptArray, navigate, setLoader } from "@/Hooks/Functions";
import { ConfirmDialog } from "@/lib/mixins";
import CartTable from "@/Pages/Checkouts/CartTable";
import { Icon } from "@iconify/react";
import { useShoppingCarts } from "@state/shopping-carts";
import { sumBy } from 'lodash';
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "sonner";
import Swal from "sweetalert2";
import Empty from "./Empty";
import { usePage } from "@inertiajs/react";

const EachCart = React.memo(({ cart, checked, onDelete, onSelect }) => {
    const handleCheckboxChange = (e) => {
        onSelect(e.target.checked, e.target.value);
    };

    return (
        <Card className="w-full mb-1">
            <CardContent className="p-2 flex relative flex-row items-start gap-2">
                <label className="inline-flex w-fit cursor-pointer items-center">
                    <Checkbox value={cart.id} checked={checked} onChange={handleCheckboxChange} className="z-[40] absolute left-4 top-4" />
                    {cart.canvases?.[0]?.mockup ?
                        <img src={cart.canvases[0].mockup} className="aspect-auto max-h-[140px]" alt="Product mockup" /> :
                        <Empty icon="line-md:downloading-loop" text="Generating Preview" />
                    }
                </label>
                <div className="flex w-full flex-1 flex-col gap-2 items-start">
                    <p className='text-sm mb-2 font-semibold'>{cart?.product?.title}</p>
                    <CartTable cart={cart} onRemove={onDelete} />
                </div>
            </CardContent>
        </Card>
    );
});

export default function ShoppingCart() {
    const { auth } = usePage().props;
    const { items, show, setShow, refetch, isLoading, loadMore, hasNextPage } = useShoppingCarts(auth?.user ? true : false);
    const [selected, setSelected] = useState([]);

    const selectedTotal = useMemo(() => {
        const selectedObj = items.filter(i => selected.includes(i.id.toString()));
        return Number(sumBy(selectedObj, (o) => Number(o.total)));
    }, [selected, items]);

    const toggleSelected = useCallback((val, value) => {
        setSelected(prev => val ? [...prev, value] : prev.filter(i => i !== value));
    }, []);

    const toggleSelectAll = useCallback((e) => {
        setSelected(e.target.checked ? items.map(i => i.id.toString()) : []);
    }, [items]);

    const deleteItem = useCallback(async (item) => {
        setShow(false);
        const result = await ConfirmDialog.fire({
            title: 'Confirmation required!',
            text: "Remove this from cart?",
            confirmButtonText: "Yes! Proceed",
            allowOutsideClick: () => !Swal.isLoading(),
        });

        if (result.isConfirmed) {
            setLoader(true, 'Deleting...')
            try {
                await window.axios.delete(route('cart.delete', { id: item.id }));
                refetch();
                toast.success("Item removed from cart");
            } catch (error) {
                toast.error("Failed to remove item from cart");
            } finally {
                setLoader(false);
            }
        }
    }, [refetch, setShow]);

    const proceedCheckout = useCallback(() => {
        if (selected.length <= 0) {
            toast.error("Please select at least one item");
            return;
        }
        encryptArray(selected).then((val) => {
            setShow(false);
            navigate(route('cart.checkout-shipping', { carts: val }));
        });
    }, [selected, setShow]);

    return (
        <>
            <Button variant="link" className="!p-1 group" onClick={() => setShow(true)}>
                <Icon icon={items.length ? "tabler:shopping-bag-exclamation" : "material-symbols:shopping-bag-outline"} className="h-6 w-6 group-hover:text-teal-600 text-white" />
            </Button>
            <Sheet open={show} onOpenChange={() => setShow(false)}>
                <SheetContent className="!z-[999] p-0 flex flex-col gap-4 md:min-w-[450px] min-w-full">
                    <SheetHeader className="gap-4 px-4 py-2 justify-between flex flex-row !items-center">
                        <label className="inline-flex items-center gap-2 text-sm font-semibold">
                            <Checkbox disabled={!items.length} onChange={toggleSelectAll} checked={selected.length === items.length} /> Select All
                        </label>
                        <SheetTitle className="mr-8">Shopping Cart</SheetTitle>
                    </SheetHeader>
                    <ScrollArea className="rounded-lg h-[calc(100vh-100px)] text-primary w-full px-2">
                        {isLoading && <div>Loading...</div>}
                        {!isLoading && !items.length && <Empty className="min-h-[80vh]" text="No cart found!" />}
                        {items.map((item) => (
                            <EachCart
                                key={item.id}
                                cart={item}
                                checked={selected.includes(item.id.toString())}
                                onDelete={deleteItem}
                                onSelect={toggleSelected}
                            />
                        ))}
                        {hasNextPage && (
                            <Button onClick={loadMore} className="w-full mt-4">
                                Load More
                            </Button>
                        )}
                    </ScrollArea>
                    <SheetFooter className="py-3 px-4 flex w-full flex-row !justify-between items-center">
                        <div className="flex flex-row justify-center items-center gap-1">
                            <span className="text-sm font-semibold">{selected.length}</span>
                            <span className="text-xs font-semibold">Selected</span>
                        </div>
                        <div className="flex flex-row justify-center items-center gap-1">
                            <span className="text-xs font-semibold">Total : </span>
                            <span className="text-sm font-semibold">$ {selectedTotal.toFixed(2)}</span>
                        </div>
                        <Button onClick={proceedCheckout} disabled={!selected.length} variant="theme">Checkout</Button>
                    </SheetFooter>
                </SheetContent>
            </Sheet>
        </>
    );
}
