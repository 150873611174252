import { useInfiniteQuery } from '@tanstack/react-query';
import { useState } from 'react';

const fetchShoppingCarts = async ({ pageParam = 1 }) => {
    const response = await window.axios.get(route("cart.paginate", { per_page: 15, page: pageParam }));
    return response.data;
};

export const useShoppingCarts = (isAuthed) => {
    const [show, setShow] = useState(false);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status,
        refetch,
    } = useInfiniteQuery({
        queryKey: ['shoppingCarts'],
        queryFn: fetchShoppingCarts,
        getNextPageParam: (lastPage) => {
            const nextPage = lastPage.current_page + 1;
            return nextPage <= lastPage.last_page ? nextPage : undefined;
        },
        enabled: isAuthed
    });

    const items = data?.pages.flatMap(page => page.data) || [];

    const loadMore = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    return {
        value: 'shopping_carts',
        label: 'Shopping Carts',
        items,
        show,
        setShow,
        loadMore,
        refetch,
        isLoading: status === 'loading',
        isError: status === 'error',
        hasNextPage,
        isFetchingNextPage,
    };
};
